<template>
  <li :class="mainDropDown.useTailwind ? 'last:mb-2' : ''">
    <label class="multilevel-dropdown__option" :class="mainDropDown.useTailwind ? 'block text-sm mb-2 inline-flex items-center cursor-pointer gap-2' : ''">
      <input
        v-if="isParent"
        :type="mainDropDown.isMulti ? 'checkbox' : 'radio'"
        class="multilevel-dropdown__checkbox"
        :checked="allDescendantsChecked"
        :class="{
          'multilevel-dropdown__checkbox size-4 text-rock-600 focus:ring-rock-600': mainDropDown.useTailwind,
          'state-indeterminate': isIndeterminent,
        }"
        @input.stop.prevent="parentToggle"
        ref="node"
      >
      <input
        v-else
        :type="mainDropDown.isMulti ? 'checkbox' : 'radio'"
        class="multilevel-dropdown__checkbox"
        :class="{
          'multilevel-dropdown__checkbox size-4 text-rock-600 focus:ring-rock-600': mainDropDown.useTailwind,
        }"
        :checked="checked"
        @click.stop="mainDropDown.toggle(value)"
        :disabled="!checked && valueLimitReached"
      >
      <!--
        in the code above if the toggle method failed then the checkbox
        be internally checked but would display as unchecked
      -->
      <span
        class="multilevel-dropdown__label"
        @click.stop.prevent="labelClick"
      >
        {{ label }}
      </span>
      <span
        class="multilevel-dropdown__chevron"
        @click.stop.prevent="toggleExpanded"
      >
        <i
          v-if="isParent"
          class="far fa-chevron-down"
          :class="{ 'fa-rotate-by': this.expanded }"
          style="transition: all linear 0.1s; --fa-rotate-angle: -180deg"
        ></i>
      </span>
    </label>
    <ul v-if="isParent"
      class="multilevel-dropdown__level"
      :class="mainDropDown.useTailwind ? 'pl-4' : ''"
      >
      <MultilevelDropdownNode
        :ref="setChildrenRef"
        v-show="expanded"
        v-for="option of options"
        :key="option.value"
        :value="option.value"
        :label="option.label"
        :options="option.options"
        :isMulti="isMulti"
      >
      </MultilevelDropdownNode>
    </ul>
  </li>
</template>

<script>

export default {
  inject: ['mainDropDown'],

  props: {
    value: {
      type: [Number, String],
    },
    label: String,
    options: {
      type: Array,
      default: () => [],
    },
    isMulti: {
      type: Boolean,
    },
  },
  data() {
    return {
      expanded: false,
      childrenRef: [],
    };
  },
  computed: {
    isIndeterminent() {
      return !this.allDescendantsChecked && this.anyDescendantsChecked;
    },
    isParent() {
      return this.options.length > 0;
    },
    valueLimitReached() {
      return this.mainDropDown.valueLimitReached;
    },
    checked() {
      return this.mainDropDown.isChecked(this.value);
    },
    // Is at least one of the descendants checked
    anyDescendantsChecked() {
      if (!this.isParent) {
        return this.checked;
      }
      return this.childrenRef.some((child) => child.anyDescendantsChecked);
    },
    // Are all the descendants checked
    allDescendantsChecked() {
      if (!this.isParent) {
        return this.checked;
      }
      return this.childrenRef.every((child) => child.allDescendantsChecked);
    },
  },
  watch: {
    isIndeterminent(val) {
      if ('node' in this.$refs) {
        this.$refs.node.indeterminate = val;
      }
    },
  },
  methods: {
    setChildrenRef(el) {
      if (el) {
        if (!this.childrenRef.includes(el)) {
          this.childrenRef.push(el);
        }
      }
    },
    labelClick() {
      if (this.isParent) {
        this.toggleExpanded();
      } else {
        this.mainDropDown.toggle(this.value);
      }
    },
    parentToggle() {
      if (this.allDescendantsChecked) {
        this.deselectChildren();
      } else {
        this.selectChildren();
      }
    },
    selectChildren() {
      if (this.isParent) {
        this.childrenRef.forEach((child) => child.selectChildren());
      } else {
        this.mainDropDown.check(this.value);
      }
    },
    deselectChildren() {
      if (this.isParent) {
        this.childrenRef.forEach((child) => child.deselectChildren());
      } else {
        this.mainDropDown.uncheck(this.value);
      }
    },
    toggleExpanded() {
      if (this.isParent) {
        this.expanded = !this.expanded;
      }
    },
  },
  mounted() {
    if ('node' in this.$refs) {
      this.$refs.node.indeterminate = this.isIndeterminent;
    }
  },
};
</script>
