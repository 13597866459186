import axios from 'axios';
import _ from 'lodash';
import * as Sentry from '@sentry/vue';

window._ = _;

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.response.use((response) => response, (error) => {
  if ('response' in error && error.response.status === 500 && error.response?.data?.trace) {
    const element = document.createElement('div');
    element.style.position = 'fixed';
    element.style.inset = 0;
    element.style.background = 'rgba(0,0,0,.8)';
    element.style.padding = '2rem';
    element.style['z-index'] = 10000;

    element.classList.add('laravel-js-error-modal');

    const header = document.createElement('h1');
    header.innerHTML = `${error.response.data.message}`;
    header.style.color = 'white';

    const h3 = document.createElement('h3');
    h3.innerHTML = `${error.response.data.exception} ${error.response.data.file}@${error.response.data.line}`;
    h3.style.color = '#e5c075';

    const ol = document.createElement('ol');

    error.response.data.trace.forEach((line) => {
      const li = document.createElement('li');
      li.innerHTML = `${line.class || ''} ${line.type || ''} ${line.function} @line ${line.line || ''}`;
      li.style.color = '#51b5c3';
      ol.append(li);
    });

    const button = document.createElement('button');
    button.innerHTML = '<i class="fas fa-xmark"></i>';
    button.style.cssText = `
      position: absolute;
      background: none;
      color: #e58875;
      border: none;
      font-size: 2rem;
      padding: 1em;
      line-height: 1em;
      top: 0;
      right: 0;
    `;

    button.addEventListener('click', function () {
      this.closest('.laravel-js-error-modal').remove();
    });

    element.append(header);
    element.append(h3);
    element.append(ol);
    element.append(button);
    document.body.appendChild(element);
  }
  throw error;
});

// eslint-disable-next-line import/prefer-default-export
export function sentryInit(vueApp) {
  if (import.meta.env.DEV) return;

  Sentry.init({
    app: vueApp,
    environment: import.meta.env.VITE_ENV,
    dsn: 'https://509a2f70a179bf28d7d5ba1f993014bf@o4507607096033280.ingest.de.sentry.io/4507607107960912',
    ignoreErrors: [/^jstag is not defined$/, /^Can't find variable: jstag$/],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/[a-z]*\.(dev\.)?eventware.com/],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}
