<template>
  <iframe
    style="position:absolute;top:0;left:0;width:100%;height:100%;"
    :src="src"
    :title="title"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  >
  </iframe>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    video_id: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      return `https://www.youtube-nocookie.com/embed/${this.video_id}`;
    },
  },
};

</script>
