<template>
  <div class="relative">
    <div
      v-if="loading"
      class="absolute right-0 top-0 size-4  text-base text-slate-300"
      role="status"
      >
      <i class="fa-solid fa-spinner fa-spin-pulse" :class="classes"></i>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    classes: {
      type: String,
      required: false,
    },
  },
};
</script>
