<template>
  <span class="text-sm" style="white-space: pre-wrap; word-break:break-word;" v-text="text"></span>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
