<template>
  <iframe
    :src="src"
    frameborder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowfullscreen
    style="position:absolute;top:0;left:0;width:100%;height:100%;"
    :title="title"></iframe>
</template>

<script>

export default {
  props: {
    event_id: {
      type: String,
      required: true,
    },
  },
  computed: {
    src() {
      return `https://vimeo.com/event/${this.event_id}/embed`;
    },
  },
};

</script>
