<template>
  <div class="floorplan">
    <div v-if="false && floorplan" class="floorplan__left-menu">
      <h5> Companies </h5>
      <li
        v-for="location in unselectedLocations"
        :key="location.id"
        @click="zoomToLocation(location)"
        style="display: block"
        class="floorplan__left-menu-item"
        :class="{ 'floorplan__left-menu-item--selected': selected === location }"
      >
        {{ location.name }}
      </li>
    </div>
    <div
      class="absolute inset-0"
      ref="container"
      :class="{ 'cursor-not-allowed': lockSelected }"
    >
      <svg
        v-if="floorplan"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="`${viewBox[0]} ${viewBox[1]} ${viewBox[2]} ${viewBox[3]}`"
        ref="svg"
      >
        <image
          x="0"
          y="0"
          :width="floorplan.width"
          :href="floorplan.background_url"
        />
        <defs>
          <pattern id="smallGrid" width="100" height="100" patternUnits="userSpaceOnUse">
            <rect
              width="100"
              height="100"
              stroke="#e2e8f0"
              stroke-wdith="1"
              fill="none"
            />
            <circle cx="0" cy="0" r="5" fill="#94a3b8"/>
            <circle cx="100" cy="0" r="5" fill="#94a3b8"/>
            <circle cx="0" cy="100" r="5" fill="#94a3b8"/>
            <circle cx="100" cy="100" r="5" fill="#94a3b8"/>
          </pattern>
          <pattern id="grid" width="500" height="500" patternUnits="userSpaceOnUse">
          <rect
            width="500"
            height="500"
            fill="url(#smallGrid)"
            stroke="#cbd5e1"
            stroke-width="2"
          />
          </pattern>
        </defs>
        <g v-if="showGrid">
          <rect
            x="0"
            y="0"
            :width="floorplan.width"
            :height="floorplan.depth"
            fill="url(#grid)"
          ></rect>
          <text
            v-for="y in Math.floor(floorplan.depth / floorplan.rounding_factor)"
            :key="y"
            :y="y * 100"
            x="-20"
            font-size="50"
            dominant-baseline="middle"
            text-anchor="end"
            fill="#64748b"
          >
            {{ y }}
          </text>
          <text
            v-for="x in Math.floor(floorplan.width / floorplan.rounding_factor)"
            :key="x"
            y="0"
            :x="x*100"
            font-size="50"
            :transform="`translate(${100 * x},-20) rotate(-45) translate(${-100 * x},0)`"
            dominant-baseline="middle"
            text-anchor="start"
            fill="#64748b"
          >
            {{ x }}
          </text>
        </g>
        <g>
          <FeFloorplanStand
            v-for="location in floorplan.locations"
            :key="location.id"
            :location="location"
            @click="(!lockSelected) && (selectedID = location.id) && $emit('locationClicked', selected)"
            />
            <FeFloorplanStand
              v-if="selected"
              :location="selected"
              fillColour="#B0E0A8"
            />
          </g>
          <g v-if="showPaths">
            <g
              v-for="path in floorplan.paths"
              :key="path.id"
            >
              <line
                :x1="path.x1"
                :y1="path.y1"
                :x2="path.x2"
                :y2="path.y2"
                stroke="red"
                stroke-width="16"
                @click="$emit('pathClicked', path)"
              />
             <circle
               :cx="path.x1"
               :cy="path.y1"
               r="24"
               fill="red"
             />
             <circle
               :cx="path.x2"
               :cy="path.y2"
               r="24"
               fill="red"
             />
            </g>
          </g>
        </svg>
        <div class="floorplan__coords bg-rock-200/50" v-if="svgX !== null && svgY !== null">
          <i class="fas fa-compass me-1"></i>
          {{ (svgX / floorplan.rounding_factor).toFixed(2) }},
          {{ (svgY / floorplan.rounding_factor).toFixed(2) }}
        </div>
      </div>

    </div>

  </template>

<script setup>
import {
  ref, watch, toRefs, onMounted, computed, nextTick,
} from 'vue';
import { useEventListener, useElementSize } from '@vueuse/core';
import FeFloorplanStand from './FeFloorplanStand.vue';

const props = defineProps({
  floorplan: Object,
  showGrid: Boolean,
  showPaths: Boolean,
  width: Number,
  height: Number,
  lockSelected: Boolean,
});

defineEmits([
  'locationClicked',
  'pathClicked',
  'hover',
]);

const { floorplan } = toRefs(props);

const selectedID = defineModel({ default: null });

const container = ref(null);

const { width: containerWidth, height: containerHeight } = useElementSize(container);

const viewBox = ref([0, 0, 0, 0]);

const selected = computed(() => floorplan.value.locations.find(
  (location) => selectedID.value === location.id,
));

onMounted(() => {
  // Scale and center the image.

  const multiplier = (containerWidth.value / containerHeight.value) * (floorplan.value.depth / floorplan.value.width);

  const viewportHeight = (1.5 * floorplan.value.depth) / Math.min(1, multiplier);
  const viewportWidth = 1.5 * floorplan.value.width * Math.max(1, multiplier);
  const startX = (viewportWidth - floorplan.value.width) / 2;
  const startY = (viewportHeight - floorplan.value.depth) / 2;
  viewBox.value = [-1 * startX, -1 * startY, viewportWidth, viewportHeight];
});

watch(containerWidth, (newVal, oldVal) => {
  if (oldVal) viewBox.value[2] *= newVal / oldVal;
});

watch(containerHeight, (newVal, oldVal) => {
  if (oldVal) viewBox.value[3] *= newVal / oldVal;
});

const svg = ref();

function transitionViewBox(
  targetX,
  targetY,
  targetWidth,
  targetHeight,
  duration = 1000,
) {
  const startX = viewBox.value[0];
  const startY = viewBox.value[1];
  const startWidth = viewBox.value[2];
  const startHeight = viewBox.value[3];

  const startTime = performance.now();

  function animate(time) {
    let progress = (time - startTime) / duration;

    progress = Math.min(1, progress); // Cap at 1

    // Simple easing function for a smooth effect (ease-in-out)
    const easeFactor = 0.5 - Math.cos(progress * Math.PI) / 2;

    // Interpolate each viewBox parameter
    viewBox.value = [
      startX + (targetX - startX) * easeFactor,
      startY + (targetY - startY) * easeFactor,
      startWidth + (targetWidth - startWidth) * easeFactor,
      startHeight + (targetHeight - startHeight) * easeFactor,
    ];

    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  }

  // Start the animation
  requestAnimationFrame(animate);
}

function zoomToLocation(location) {
  const newWidth = 2000;
  const newHeight = 1000;

  selectedID.value = location.id;

  transitionViewBox(
    location.centre_x - (newWidth / 2),
    location.centre_y - (newHeight / 2),
    newWidth,
    newHeight,
  );
}

function screenToSvg(x, y) {
  const ctm = svg.value.getScreenCTM();

  return DOMPointReadOnly.fromPoint({ x, y })
    .matrixTransform(ctm.inverse());
}

function zoomToPoint(scaleFactor, x, y) {
  const svgPoint = screenToSvg(x, y);

  viewBox.value = [
    (viewBox.value[0] - svgPoint.x) * scaleFactor + svgPoint.x,
    (viewBox.value[1] - svgPoint.y) * scaleFactor + svgPoint.y,
    viewBox.value[2] * scaleFactor,
    viewBox.value[3] * scaleFactor,
  ];
}

function pan(x, y) {
  const ctm = svg.value.getScreenCTM();

  const panned = new DOMMatrix([0, 0, x, y, 0, 0]).multiply(ctm.inverse());

  viewBox.value[0] += panned.c - panned.a;
  viewBox.value[1] += panned.d - panned.b;
}

useEventListener(svg, 'wheel', (event) => {
  zoomToPoint(1 + 0.01 * event.deltaY, event.clientX, event.clientY);
  event.preventDefault();
});

let isPanning = false;

useEventListener(svg, 'mousedown', () => {
  isPanning = true;
});

useEventListener(svg, 'mousemove', (event) => {
  if (isPanning) {
    pan(-1 * event.movementX, -1 * event.movementY);
  }
});

useEventListener(window, 'mouseup', () => {
  isPanning = false;
});

watch(
  floorplan,
  () => floorplan.value.locations.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }),
);

const svgX = ref(null);
const svgY = ref(null);

useEventListener(svg, 'mousemove', ({ clientX, clientY }) => {
  const { x, y } = (screenToSvg(clientX, clientY));
  svgX.value = x;
  svgY.value = y;
});

async function download() {
  const oldSelectedID = selectedID.value;
  selectedID.value = null;

  await nextTick();

  const blob = new Blob(
    [svg.value.outerHTML],
    { type: 'text/svg' },
  );
  selectedID.value = oldSelectedID;
  const filename = 'floorplan.svg';

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}

defineExpose({
  download,
});

const unselectedLocations = computed(() => floorplan.value.locations.filter(
  (location) => location.id !== selectedID.value,
));
</script>

<style>

.floorplan {
  position: relative;
  background-color: #ffffff;
  border: 1px solid var(--gray-2);
  display: grid;
  grid-template-columns: auto;
  width:100%;
  height: 100%;
  max-width:100%;
}

.floorplan__left-menu {
  background: #eee;
  border-right: 4px;
  padding: 1rem;
}

.floorplan__left-menu-item--selected {
  text-decoration: underline;
}

.floorplan__coords {
position: absolute;
right: 0;
bottom: 0;
margin: 1rem;
padding: 0.5rem;
min-width: 10rem;
text-align: center;
font-size: 12px;
z-index: 100;
}

</style>
